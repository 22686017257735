import request from '@/utils/request'

//无token
export function fetchData() {
  return request({
    url: '/front-api/api/v1/routine/findHomePage',
    method: 'get'
  })
}

export function fetchBanners() {
  return request({
    url: '/jjh/banner/list',
    method: 'get'
  })
}

export function fetchActivityPhoto() {
  return request({
    url: '/jjh/getActivityBanner',
    method: 'get'
  })
}

export function fetchActivityList(query) {
  return request({
    url: '/jjh/homePage',
    method: 'get',
    query
  })
}

