import request from '@/utils/request'

//无token
export function fetchData(query) {
  return request({
    url: `/jjh/homePage/more`,
    method: 'get',
    params: query
  })
}

export function fetchClassData(query) {
  return request({
    url: `/jjh/user/study`,
    method: 'get',
    params: query
  })
}

export function handleEnroll(data) {
  return request({
    url: `/jjh/user/register`,
    method: 'post',
    data
  })
}

//无token
export function getTrainingDetail(id) {
  return request({
    url: '/jjh/training/' + id,
    method: 'get'
  })
}

export function changeFavorite(data) {
  return request({
    url: '/jjh/collection/add',
    method: 'post',
    data
  })
}


export function getComments(query) {
  return request({
    url: '/jjh/trainings/comment',
    method: 'get',
    params: query
  })
}

export function addComment(data) {
  return request({
    url: '/jjh/trainings/comment',
    method: 'post',
    data
  })
}

export function saveRecord(data) {
  return request({
    url: '/jjh/users/course/history/save',
    method: 'post',
    data
  })
}

export function getHistory() {
  return request({
    url: '/jjh/users/course/recent',
    method: 'get'
  })
}

export function classDetail(id) {
  return request({
    url: '/jjh/course/' + id,
    method: 'get'
  })
}

export function getActivityDetail(id) {
  return request({
    url: '/jjh/activity/' + id,
    method: 'get'
  })
}


export function fetchActivityData(query) {
  return request({
    url: `/jjh/activities/filter`,
    method: 'get',
    params: query
  })
}