<template>
  <div class="home">
    <!-- <el-carousel height="500px">
      <el-carousel-item v-for="item in 4" :key="item">  
        <img alt="Vue logo" src="../assets/logo.png" height="500px" />
      </el-carousel-item>
    </el-carousel> -->
    <div class="section">
    <div class="title">
        培训活动通知
        <div class="more" @click="$router.push('/activityList')">查看更多 ></div>
      </div>
    <div>
      <div v-for="item in newsList.slice(0, 5)" :key="item.id" class="newsItem" @click="goNews(item)">
        {{ item.name }}
        <span style="float: right; color: #d2d2d2">{{ item.pubTime }}</span>
      </div>
    </div>
    </div>
    <div class="section" v-for="(item, i) in homeData" :key="item.typeId" :style="{ marginBottom: i === homeData.length - 1 ? '60px' : '0'}">
      <div class="title">
        {{ item.typeName }}
        <div class="more" @click="$router.push('/trainingList/'+item.typeId)">查看更多 ></div>
      </div>
      <div style="position: relative;">
        <!-- <transition-group name="list" tag="ul" class="infinite-list" v-infinite-scroll="load" style="overflow: auto"> -->
        <ul
          class="infinite-list"
          v-infinite-scroll="load"
          style="overflow: auto"
        >
          <li
            v-for="(training, index) in item.trainings"
            class="infinite-list-item"
            :key="training.id"
            @click="goDetail(training, index)"
          >
            <div
              style="
                text-align: center;
                border-right: 1px solid #d3d3d3;
                padding: 0 10px;
                color: #aaaaaa;
                width: 200px;
                height: calc(100% - 20px);
              "
            >
              <img style="width: 200px; object-fit: contain; height: 100%;" :src="training.coverImgUrl" />
            </div>
            <div style="padding: 0 10px; width: calc(100% - 130px)">
              <div
                style="
                  font-weight: 600;
                  font-size: 18px;
                  max-width: 100%;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                "
              >
                {{ training.name }}
              </div>
              <div
                style="
                  font-size: 14px;
                  max-width: 100%;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  color: #aaaaaa;
                  margin-top: 60px;
                "
              >
                时间：{{ training.startTime }} - {{ training.endTime }}
              </div>
            </div>
            <div style="text-align: center; padding: 0 0 0 10px">
              <div style="font-size: 20px">
                <img
                  src="@/assets/icons/go.png"
                  style="width: 30px; height: 30px"
                />
              </div>
            </div>
          </li>
        </ul>
        <!-- </transition-group> -->
      </div>
    </div>
  </div>
</template>

<script>
import { fetchActivityPhoto, fetchActivityList } from '@/api/home'
import { fetchActivityData } from '@/api/news'
export default {
  name: "HomeView",
  data() {
    return {
      count: 15,
      imgSrc: require(`@/assets/login-logo.png`),
      curItem: null,
      curPic: 0,
      curIndex: 0,
      publicPath: process.env.BASE_URL,
      listQuery: {
        pageSize: 5,
        pageNum: 1
      },
      newsList: [],
      rollingIndex: 0,
      rolling: true,
      newsTimer: null,
      activityPhoto: {},
      homeData: null
    };
  },
  mounted() {

    document.getElementsByClassName("el-header")[0].scrollIntoView();
    fetchActivityPhoto().then((res) => {
      this.activityPhoto = res.data[0]
      console.log(this.activityPhoto)
    })
    fetchActivityData({name: ''}).then((res) => {
      this.newsList = res.data
    })
    fetchActivityList().then((res) => {
      this.homeData = res.data.homePages
    })
  },
  beforeDestroy() {
    clearInterval(this.newsTimer)
  },
  methods: {
    stopRolling() {
      this.rolling = false
    },
    startRolling() {
      this.rolling = true
    },
    jumpTo(url) {
      window.open(url);
    },
    jumpToLogin(url) {
      if (this.$store.state.login) {
        window.open(url+`?username=${JSON.parse(localStorage.getItem('user')).username}&password=${JSON.parse(localStorage.getItem('user')).password}`);
      } else {
        window.open(url)
      }
    },
    picChange(index, item, curIndex) {
      console.log(index);
      this.curItem = item;
      this.curIndex = curIndex;
      this.curPic = index;
      this.imgSrc = index;
    },
    load() {
      console.log("load");
    },
    goNews(item) {
      this.$router.push({
        path: "/detail/" + item.id,
        query: { type: "activity" },
      });
    },
    goDetail(item, index) {
      console.log(index);
      this.$router.push({
        path: "/detail/" + item.id,
        query: { type: "training" },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-move,.list-enter-active, .list-leave-active {transition: 2s;}

.home {
  background: white;
}

.newsItem {
  padding: 10px 0;
  border-bottom: 1px solid #e3e3e3;
  cursor: pointer;
}
.collab {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/images/banner_leftbg.png");
}
.cases {
  .left {
    width: calc(70% - 20px);
    margin-right: 20px;
    display: inline-block;
    height: 100%;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }
  .right {
    display: inline-block;
    height: 100%;
    width: 30%;

    .up,
    .down {
      height: calc(50% - 10px);
      position: relative;
      cursor: pointer;
      img {
        position: absolute;
        width: 100%;
        height: calc(100% - 30px);
        object-fit: cover;
      }
    }
    .up {
      margin-bottom: 20px;
    }
  }
}
.collabPage {
  width: 100%;
  height: 100px;
  .collabItem {
    background: white;
    width: calc(25% - 22px);
    height: 80px;
    margin: 10px;
    display: inline-block;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
    img {
      width: 100%;
      object-fit: cover;
      height: 80px;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.infinite-list::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.infinite-list {
  display: inline-block;
  height: 300px;
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
  margin-right: 20px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
  .infinite-list-item {
    display: flex;
    align-items: center;
    height: 160px;
    background: #ffffff;
    border-bottom: 1px solid #d3d3d3;
    cursor: pointer;
  }
  .infinite-list-item:nth-child(1) {
    border-top: 1px solid #d3d3d3;
  }
}

.section {
  margin-top: 60px;
  text-align: left;
  .title {
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .more {
    font-size: 12px;
    font-weight: 400;
    float: right;
    cursor: pointer;
  }
}
.buttons {
  display: flex;
  justify-content: space-between;
  .item {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
}
</style>
