import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsView.vue')
  },
  {
    path: '/survey',
    name: 'survey',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SurveyView.vue')
  },
  {
    path: '/cases',
    name: 'cases',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CasesView.vue')
  },
  {
    path: '/collab',
    name: 'collab',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CollabView.vue')
  },
  {
    path: '/apps',
    name: 'apps',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AppsView.vue')
  },
  {
    path: '/detail/:id(\\d+)?',
    name: 'detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/DetailView.vue')
  },
  {
    path: '/surveyDetail/:id(\\d+)?',
    name: 'surveyDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/SurveyDetail.vue')
  },
  {
    path: '/trainingList/:id(\\d+)?',
    name: 'trainingList',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainingView.vue')
  },
  {
    path: '/activityList',
    name: 'activityList',
    component: () => import(/* webpackChunkName: "about" */ '../views/activityView.vue')
  },
  {
    path: '/points',
    name: 'points',
    component: () => import(/* webpackChunkName: "about" */ '../views/pointsView.vue')
  },
  {
    path: '/certs',
    name: 'certs',
    component: () => import(/* webpackChunkName: "about" */ '../views/certView.vue')
  },
  {
    path: '/tests',
    name: 'tests',
    component: () => import(/* webpackChunkName: "about" */ '../views/testView.vue')
  },
  {
    path: '/myTraining',
    name: 'myTraining',
    component: () => import(/* webpackChunkName: "about" */ '../views/myTraining.vue')
  },
  {
    path: '/myFavorites',
    name: 'myFavorites',
    component: () => import(/* webpackChunkName: "about" */ '../views/myFavorites.vue')
  },
  {
    path: '/class/:id(\\d+)?',
    name: 'class',
    component: () => import(/* webpackChunkName: "about" */ '../views/classView.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to, store.state.login)
  if (!store.state.login && to.name != 'login' && to.name != 'about') {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
