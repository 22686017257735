import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login: false
  },
  getters: {
  },
  mutations: {
    CHANGE_LOGIN: (state, value) => {
      // eslint-disable-next-line no-prototype-builtins
      state.login = value
    }
  },
  actions: {
    changeLogin({ commit }, data) {
      commit('CHANGE_LOGIN', data)
    }
  },
  modules: {
  }
})
