<template>
  <div id="app">
    <template>
      <el-header>
        <img
          src="@/assets/login-logo.png"
          style="width: 150px; object-fit: contain; cursor: pointer;"
          @click="$router.push('/')"
        />
        <el-menu
          :default-active="activePath"
          class="el-menu-demo"
          style="min-width: 648px"
          mode="horizontal"
          :router="true"
          @select="handleSelect"
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/news">我的课程</el-menu-item>
          <el-menu-item index="/about">个人中心</el-menu-item>
        </el-menu>
        <el-dropdown
          class="avatar-container right-menu-item hover-effect"
          trigger="click"
        >
          <div class="avatar-wrapper">
            <img
              :src="headSrc"
              class="user-avatar"
            />
            <i class="el-icon-caret-bottom" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <!-- <router-link to="/profile/index">
            <el-dropdown-item>
              {{ $t('navbar.profile') }}
            </el-dropdown-item>
          </router-link> -->
            <!-- <a target="_blank" href="https://github.com/PanJiaChen/vue-element-admin/">
            <el-dropdown-item>
              {{ $t('navbar.github') }}
            </el-dropdown-item>
          </a>
          <a target="_blank" href="https://panjiachen.github.io/vue-element-admin-site/#/">
            <el-dropdown-item>Docs</el-dropdown-item>
          </a> -->
            <el-dropdown-item @click.native="goLogin" v-if="!login">
              <span style="display: block; cursor: pointer">{{ "登录" }}</span>
            </el-dropdown-item>
            <el-dropdown-item @click.native="goInfo" v-if="login">
              <span style="display: block; cursor: pointer">{{ "个人中心" }}</span>
            </el-dropdown-item>
            <el-dropdown-item divided @click.native="goLogout" v-if="login">
              <span style="display: block; cursor: pointer">{{ "退出系统" }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>
        <!-- <div id="banner">
          <el-carousel height="600px" v-if="$route.path=='/'" type="card" style="min-width: 1600px">
            <el-carousel-item v-for="item in banners" :key="item.id">
              <div style="position: relative;">
                <div class="bannerContent">
                  <div class="title">中国红十字基金会<div>“博爱基层公卫援建计划”</div></div>
                  <div class="content"> “博爱基层公卫援建计划”是中国红十字基金会与上海大丰公益基金会共同设立的专项公益计划，由各地红十字会和卫健部门支持实施</div>
                  <div style="color: #BC1419; width: fit-content; padding: 8px 25px 13px 25px; height: 20px; background: white; border-radius: 3px; cursor: pointer"
                  @click="$router.push({path: '/detail/'+99, query:{type: 'news'}})">点击进入</div>
                </div
                <img :src="item.img" height="600px" style="object-fit: cover; width: 100%; cursor: pointer;" @click="goto(item.contentAddress)" />

              </div>
            </el-carousel-item>
            <el-carousel-item v-if="banners.length === 0">
              <div style="position: relative">
                <div class="bannerContent">
                  <div class="title">中国红十字基金会<div>“博爱基层公卫援建计划”</div></div>
                  <div class="content"> “博爱基层公卫援建计划”是中国红十字基金会与上海大丰公益基金会共同设立的专项公益计划，由各地红十字会和卫健部门支持实施</div>
                  <div style="color: #BC1419; width: fit-content; padding: 8px 25px 13px 25px; height: 20px; background: white; border-radius: 3px; cursor: pointer"
                  @click="$router.push({path: '/detail/'+99, query:{type: 'news'}})">点击进入</div>
                </div>
                <img src="./assets/banner1.png" height="600px" style="object-fit: cover; width: 100%; cursor: pointer" />

              </div>
            </el-carousel-item>
          </el-carousel>
        </div> -->
      <el-main v-if="$route.path != '/about' && $route.path != '/login'">
        <router-view ref="view" />
      </el-main>
      <template v-else>
        <router-view />
      </template>
      <el-footer style="text-align: center; padding: 24px; font-size: 12px">
        <div style="color: #BBBBBB"><span>版权所有：南华和平医院管理（湖南）有限公司</span><span style="margin-left: 10px">技术支持：瓦戈技术（深圳）有限公司</span><span style="margin-left: 10px">沪ICP备2021019409号-1</span></div>
        <div style="color: #BBBBBB; margin-top: 10px; display: flex; justify-content: center;line-height: 20px"><img src="@/assets/beian.png" style="width:20px;height:20px" />沪公网安备43010302001472号</div>
      </el-footer>
    </template>
  </div>
</template>

<script>
// import { fetchBanners } from '@/api/home'
import { getInfo } from '@/api/user'
export default {
  data() {
    return {
      banners: [],
      userInfo: {}
    }
  },
  computed: {
    headSrc() {
      if (this.$store.state.login) {
        if (this.userInfo?.img) {
          return this.userInfo.img
        }
        return require('./assets/default_head.png')
      }
      return require('./assets/default_head.png')
    },
    login() {
      return this.$store.state.login;
    },
    activePath() {
      console.log(this.$route)
      if (this.$route.name == 'detail') {
        switch (this.$route.query.type) {
          case 'news':
            return '/news'
          case 'cases':
            return '/cases'
          case 'collab':
            return '/collab'
        }
      }
      if (this.$route.name == 'surveyDetail') {
        return '/survey'
      }
      if (this.$route.name == 'login') {
        return '/'
      }
      return this.$route.path
    }
  },
  mounted() {
    // fetchBanners().then((res) => {
    //   this.banners = res.data
    // })
    getInfo().then((res) => {
      this.userInfo = res.data
    })
  },
  methods: {
    goto(url) {
      let type = url.split(':')[0]
      let id = url.split(':')[1]
      this.$router.push({
        path: "/detail/" + id,
        query: { type: type === 'a' ? "training" : "activity" },
      });
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      if (this.$route.path == '/apps' && keyPath == '/apps') {
        this.$refs['view'].goBack()
      }
      if (key == null) {
        window.open('https://www.bsc1.cn/BSCEdu/education/video/front/index')
      }
      if (!this.$store.state.login && keyPath == '/about') {
        this.$nextTick(() => {
          this.$router.push('/login')
          this.$notify({
                type: 'warning',
                title: '提示',
                message: '需要登陆后操作'
              })

        })
      }
    },
    goLogout() {
      this.$store.dispatch("changeLogin", false);
      this.$alert("您已登出", "提示", {
        confirmButtonText: "确定",
        type: "success",
        confirmButtonClass: "el-button el-button--danger",
      });
      localStorage.setItem('token', '')
      localStorage.removeItem('user')
      this.goLogin()
    },
    goLogin() {
      if (this.$route.path != '/login'){
        this.$router.push("/login");
      }
    },
    goInfo() {
      if (this.$route.path != '/about'){
        this.$router.push("/about");
      }
    }
  },
};
</script>

<style lang="scss">
#app .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 4px solid #ff404a;
}
#app .el-menu--horizontal>.el-menu-item {
  height: 100px;
  line-height: 100px;
  width: 33%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: overlay;
  #banner {
    .el-carousel__arrow--right {
      right: calc(25% - 100px)
    }
    .el-carousel__arrow--left {
      left: calc(25% - 100px)
    }
  }
}

.bannerContent {
  position: absolute;
  text-align: left;
  left: 25%;
  bottom: 50px;
  padding: 70px;
  width: 480px;
  min-height: 260px;
  background:rgba(178, 31, 42, 0.8000);
  .title {
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  .content {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 20px;

  }
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.el-footer {
  color: white;
  background: rgb(70, 69, 69);
  height: 100px !important;
  min-width: 1600px;
}
.el-main {
  min-height: calc(100vh - 120px);
  padding: 0 25% !important;
  min-width: 1600px;
}
.el-header {
  height: 100px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: space-between;
  padding: 0 20% !important;
  min-width: 1600px;
}

.avatar-container {
  margin-right: 30px;

  .avatar-wrapper {
    margin-top: 27px;
    position: relative;

    .user-avatar {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .el-icon-caret-bottom {
      cursor: pointer;
      position: absolute;
      right: -20px;
      top: 25px;
      font-size: 12px;
    }
  }
}
</style>
