import request from '@/utils/request'
import axios from 'axios'

//无token
export function login(data) {
  return axios({
    url: '/jjh/user/boai/login',
    method: 'post',
    data
  })
}

export function getInfo(query) {
  return request({
    url: '/jjh/user/boai/findUserInfo',
    method: 'get',
    params: query
  })
}


export function changePassword(data) {
  return request({
    url: '/jjh/user/boai/updatePassword',
    method: 'put',
    data
  })
}

export function forgetPassword(data) {
  return request({
    url: '/jjh/user/boai/updatePasswordCheckMessage',
    method: 'post',
    data
  })
}

export function sendMsg(data) {
  return request({
    url: '/jjh/user/boai/updatePasswordSentMessage',
    method: 'post',
    data
  })
}